import { IoChevronForward } from "react-icons/io5";
import Header from "../../components/Header/index";
import SearchWithFilter from "../../components/SearchWithFilter";
import TabNavbar from "../../components/Tabbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CourseData } from "../../data/CourseData";

function Courses() {
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const itemsPerPage = 4; // Number of items per page
  const totalPages = Math.ceil(CourseData.length / itemsPerPage);

  const navigate = useNavigate();

  // Get current items to display
  const currentCourseData = CourseData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (page: number) => setCurrentPage(page);

  return (
    <div className="flex flex-col items-start gap-8">
      <Header />
      <TabNavbar id="courses"/>
      <SearchWithFilter />
      <div className="flex flex-col w-full justify-start items-start gap-8 px-10">
        {currentCourseData.map((item, i) => (
          <>
            <div
              onClick={() => navigate("/courses/sessions/" + item.id)}
              className="flex flex-row w-full justify-between items-center group cursor-pointer"
            >
              <div className="flex flex-col w-full">
                <p className="text-2xl font-semibold text-[#961e38]">
                  {item.title}
                </p>
                <div className="flex items-center justify-start gap-5 pt-2">
                  <p className="text-xs font-semibold flex items-center justify-start gap-2">
                    <span className="text-[#b5b5b5]">Type:</span>
                    <span>{item.type}</span>
                  </p>
                  <p className="text-xs font-semibold flex items-center justify-start gap-2">
                    <span className="text-[#b5b5b5]">Lang:</span>
                    <span>{item.langL}</span>
                  </p>
                  <p className="text-xs font-semibold flex items-center justify-start gap-2">
                    <span className="text-[#b5b5b5]">Min job lvl:</span>
                    <span>{item.minJobLvl}</span>
                  </p>
                </div>
              </div>
              {/* Icon changes color on hover */}
              <IoChevronForward
                size={40}
                className="text-[#464646] group-hover:text-[#961e38] transition-colors duration-300"
              />
            </div>
            <div className="border border-[#b5b5b5] w-full" />
          </>
        ))}

        {/* Pagination Controls */}
        <div className="flex justify-center items-center gap-4 mt-4">
          <button
            className={`px-4 py-2 border rounded ${
              currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`px-4 py-2 border rounded ${
                currentPage === index + 1
                  ? "bg-[#961e38] text-white"
                  : "bg-white text-[#961e38]"
              }`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className={`px-4 py-2 border rounded ${
              currentPage === totalPages ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default Courses;
