export const SeatAllocations = [
  {
    id: 1,
    title: "Croatia",
    value: 30,
  },
  {
    id: 2,
    title: "Bosnia Herzegovina",
    value: 35,
  },
  {
    id: 3,
    title: "Poland",
    value: 45,
  },
  {
    id: 4,
    title: "Serbia",
    value: 28,
  },
  {
    id: 5,
    title: "Hungary",
    value: 1,
  },
];
