export const CourseData = [
	{
	  id: 1,
	  title: "Londa Reverse & Color Corection",
	  type: "Group",
	  langL: "En",
	  minJobLvl: 3,
	},
	{
	  id: 2,
	  title: "Wella Seminar Craft Tunsoare de Salon",
	  type: "Local",
	  langL: "En",
	  minJobLvl: 1,
	},
	{
	  id: 3,
	  title: "Wella sonea ILT course 223",
	  type: "Group",
	  langL: "En",
	  minJobLvl: 2,
	},
	{
	  id: 4,
	  title: "Londa style ILT course 5213",
	  type: "Local",
	  langL: "En",
	  minJobLvl: 3,
	},
	{
	  id: 5,
	  title: "Advanced Haircutting Techniques",
	  type: "Workshop",
	  langL: "En",
	  minJobLvl: 2,
	},
	{
	  id: 6,
	  title: "Coloring Masterclass",
	  type: "Online",
	  langL: "En",
	  minJobLvl: 4,
	},
	{
	  id: 7,
	  title: "Introduction to Salon Management",
	  type: "Group",
	  langL: "Fr",
	  minJobLvl: 1,
	},
	{
	  id: 8,
	  title: "Keratin Treatment Basics",
	  type: "Workshop",
	  langL: "En",
	  minJobLvl: 2,
	},
	{
	  id: 9,
	  title: "Professional Blowdry Techniques",
	  type: "Online",
	  langL: "Es",
	  minJobLvl: 1,
	},
	{
	  id: 10,
	  title: "Hair Styling for Events",
	  type: "Group",
	  langL: "En",
	  minJobLvl: 3,
	},
	{
	  id: 11,
	  title: "Men's Barbering Mastery",
	  type: "Workshop",
	  langL: "En",
	  minJobLvl: 2,
	},
	{
	  id: 12,
	  title: "Braiding and Hair Extensions",
	  type: "Local",
	  langL: "En",
	  minJobLvl: 3,
	},
	{
	  id: 13,
	  title: "Salon Safety and Hygiene",
	  type: "Online",
	  langL: "Fr",
	  minJobLvl: 1,
	},
	{
	  id: 14,
	  title: "Hair Coloring Trends 2024",
	  type: "Workshop",
	  langL: "En",
	  minJobLvl: 4,
	},
	{
	  id: 15,
	  title: "Perming Techniques for Beginners",
	  type: "Group",
	  langL: "En",
	  minJobLvl: 2,
	},
	{
	  id: 16,
	  title: "Hair Straightening Essentials",
	  type: "Local",
	  langL: "Es",
	  minJobLvl: 3,
	},
	{
	  id: 17,
	  title: "Advanced Salon Marketing",
	  type: "Online",
	  langL: "En",
	  minJobLvl: 1,
	},
	{
	  id: 18,
	  title: "Curly Hair Techniques",
	  type: "Workshop",
	  langL: "En",
	  minJobLvl: 2,
	},
	{
	  id: 19,
	  title: "Salon Staff Training 101",
	  type: "Group",
	  langL: "Fr",
	  minJobLvl: 3,
	},
	{
	  id: 20,
	  title: "Luxury Hair Care Products Workshop",
	  type: "Local",
	  langL: "En",
	  minJobLvl: 4,
	},
  ];
  