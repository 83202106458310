import { useState } from "react";
import { GoSearch } from "react-icons/go";
import { IoFunnelOutline } from "react-icons/io5";
import Header from "../../components/Header";
import TabNavbar from "../../components/Tabbar";
import { SessionData } from "../../data/SessionData";
import { SeatAllocations } from "../../data/SeatAllocation";

function SeatAllocation() {
  const [selectedType, setSelectedType] = useState("branch");

  const selectedTypeClassName = "bg-white text-[#961e38]";

  return (
    <div className="flex flex-col items-start gap-8">
      <Header />
      <TabNavbar id="seat-allocation" />
      <div className="w-full h-full">
        <table className="w-full border-collapse ">
          <thead className="bg-[#961e38] w-full text-white rounded-t-xl">
            <tr className="rounded-t-xl">
              <th colSpan={2} className="py-2 text-left rounded-t-xl">
                <div className="px-3 py-2 flex items-center justify-between gap-8">
                  <div className="flex items-center gap-2 text-base cursor-pointer">
                    <IoFunnelOutline size={18} />
                    Filter
                  </div>
                  <div className="flex items-center gap-2 w-full">
                    <GoSearch size={18} />
                    <input
                      type="text"
                      placeholder="Search"
                      className="w-full p-2 border-b-2 border-white bg-inherit focus:border-white focus:outline-none"
                    />
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th className="py-2 px-4 text-left">
                <div className="flex items-center gap-3">
                  <div
                    className={`py-1.5 px-10 cursor-pointer font-semibold border-white ${
                      selectedType === "branch"
                        ? selectedTypeClassName
                        : "bg-[#961e38] text-white border"
                    }`}
                    onClick={() => setSelectedType("branch")}
                  >
                    Branch
                  </div>
                  <div
                    className={`py-1.5 px-10 cursor-pointer font-semibold border border-white ${
                      selectedType === "group"
                        ? selectedTypeClassName
                        : "bg-[#961e38] text-white border"
                    }`}
                    onClick={() => setSelectedType("group")}
                  >
                    Group
                  </div>
                </div>
              </th>
              <th className="py-2 px-4 text-right font-normal text-base">
                Number of Seats
              </th>
            </tr>
          </thead>
          <tbody>
            {SeatAllocations.map((seat, i) => (
              <tr key={i}>
                <td className="border-b border-[#c6c6c6] py-2 px-4 text-left">
                  <p key={i}>{seat.title}</p>
                </td>
                <td className="border-b border-[#c6c6c6] py-2 px-4 text-right">
                  <input
                    className="w-[50px] border border-[#909090] focus:outline-[#909090] rounded-none text-center text-sm py-0.5"
                    placeholder="XX"
                    value={seat.value}
                  />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="bg-[#961e38]">
            <tr>
              <td className="py-3 px-5 text-white rounded-bl-xl">
                <div className="flex items-center gap-3 rounded-bl-xl">
                  <p className="text-sm">10</p>
                  <button className="py-1.5 px-6 font-semibold bg-[#961e38] border border-white hover:bg-white hover:text-[#961e38] transition">
                    Show More
                  </button>
                </div>
              </td>

              <td className="p-5 text-left text-white gap-5 rounded-br-xl">
                <div className="flex items-center justify-start gap-2 rounded-br-xl">
                  <p className="text-sm">Min Seats confirmed</p>
                  <input className="size-6 bg-white border border-[#909090] focus:outline-[#909090]" />
                  <p className="text-sm">Max Seats confirmed</p>
                  <input className="size-6 bg-white border border-[#909090] focus:outline-[#909090]" />
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default SeatAllocation;
