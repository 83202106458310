import React from "react";
import { FaUserSlash } from "react-icons/fa";
import { BiSolidCalculator } from "react-icons/bi";
import { MdOutlineEventSeat } from "react-icons/md";
import { PiCoinsThin } from "react-icons/pi";

function TabNavbar({ id }: { id: string }) {
  const x = [
    {
      id: 1,
      icon: <FaUserSlash color="#752938" size={20} />,
      title: "SUSPENSIONS",
      url: "#",
    },
    {
      id: 2,
      icon: <BiSolidCalculator color="#752938" size={20} />,
      title: "REPORTS",
      url: "#",
    },
    {
      id: 3,
      icon: <MdOutlineEventSeat color="#752938" size={20} />,
      title: "SEAT ALLOCATION",
      url: "#",
    },
    {
      id: 4,
      icon: <PiCoinsThin color="#752938" size={20} />,
      title: "TRAINING COST",
      url: "#",
    },
  ];

  return (
    <div className="flex items-center justify-between gap-5 w-full">
      <div className="flex items-center justify-start gap-5 w-full">
        {x.map((item) => {
          return (
            <div className="flex items-center justify-start gap-2 cursor-pointer group hover:underline hover:underline-offset-2 duration-200 transition-all decoration-[#752938]">
              <div>{item.icon}</div>
              <p className="text-sm text-[#752938]">{item.title}</p>
            </div>
          );
        })}
      </div>
      {id === "seat-allocation" && (
        <div className="flex items-center gap-5 w-full justify-end">
          <div className="py-2 px-4 cursor-pointer font-semibold bg-[#961e38] text-white">
            RUN SET AUTOMATION
          </div>
          <div className="py-2 px-4 cursor-pointer font-semibold bg-[#961e38] text-white">
            SAVE & PUBLISH TRAINING
          </div>
        </div>
      )}
    </div>
  );
}

export default TabNavbar;
