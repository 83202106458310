import React, { useEffect } from "react";
import { IoChevronBackSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { CourseData } from "../../data/CourseData";
import { SessionData } from "../../data/SessionData";

function Sessions() {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [courseData, setCourseData] = React.useState<any>({});

  const handleSeatAllocationNavigation = (id: number) => {
    navigate(`${pathname}/seat-allocation/${id}`);
  };

  useEffect(() => {
    const id = pathname.split("/")[3];

    const selectedCourse = CourseData.find(
      (course) => course.id === parseInt(id)
    );

    if (selectedCourse) setCourseData(selectedCourse);
    else navigate("/");
  }, [pathname]);

  return (
    <div className="flex flex-col items-start justify-start gap-10">
      <div
        onClick={() => navigate(-1)}
        className="flex flex-row items-center justify-start gap-2 cursor-pointer hover:text-[#961e38] duration-300 transition-all"
      >
        <IoChevronBackSharp color="#961e38" />
        <p>Back</p>
      </div>
      {Object.keys(courseData).length > 0 && (
        <div className="flex flex-row w-full justify-between items-center group cursor-pointer">
          <div className="flex flex-col w-full">
            <p className="text-2xl font-semibold text-[#961e38]">
              {courseData.title}
            </p>
            <div className="flex items-center justify-start gap-5 pt-2">
              <p className="text-xs font-semibold flex items-center justify-start gap-2">
                <span className="text-[#b5b5b5]">Type:</span>
                <span>{courseData.type}</span>
              </p>
              <p className="text-xs font-semibold flex items-center justify-start gap-2">
                <span className="text-[#b5b5b5]">Lang:</span>
                <span>{courseData.langL}</span>
              </p>
              <p className="text-xs font-semibold flex items-center justify-start gap-2">
                <span className="text-[#b5b5b5]">Min job lvl:</span>
                <span>{courseData.minJobLvl}</span>
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="py-2 px-4 cursor-pointer border border-[#961e38] text-[#961e38] font-semibold hover:bg-[#961e38] hover:text-white hover:rounded-md duration-300 transition-all">
        DOWNLOAD DESCRIPTION
      </div>
      <div className="w-full h-full">
        <table>
          <thead>
            <tr>
              <th className="border-b border-[#961e38] py-2 px-4">
                Session Dates
              </th>
              <th className="border-b border-[#961e38] py-2 px-4">
                Enrollment Deadline
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 text-left">
                Location
              </th>
              <th className="border-b border-[#961e38] py-2 px-4">Trainer</th>
              <th className="border-b border-[#961e38] py-2 px-4">Cost</th>
              <th className="border-b border-[#961e38] py-2 px-4">Duration</th>
              <th className="border-b border-[#961e38] py-2 px-4">
                Seat Allocation
              </th>
            </tr>
          </thead>
          <tbody>
            {SessionData.map((session, i) => (
              <tr key={i}>
                <td className="border-b border-[#c6c6c6] py-2 px-4 text-center">
                  {session["Session Dates"].map((date, i) => (
                    <p key={i}>{date}</p>
                  ))}
                </td>
                <td className="border-b border-[#c6c6c6] py-2 px-4 text-center">
                  {session["Enrollment Deadline"]}
                </td>
                <td className="border-b border-[#c6c6c6] py-2 px-4 text-left">
                  {session.Location}
                </td>
                <td className="border-b border-[#c6c6c6] py-2 px-4 text-center">
                  {session.Trainer}
                </td>
                <td className="border-b border-[#c6c6c6] py-2 px-4 text-center">
                  {session.Cost}
                </td>
                <td className="border-b border-[#c6c6c6] py-2 px-4 text-center">
                  {session.Duration}
                </td>
                <td className="border-b border-[#c6c6c6] py-2 px-4 text-center">
                  <div
                    className="py-2 px-4 cursor-pointer font-semibold bg-[#961e38] text-white"
                    onClick={() => {
                      handleSeatAllocationNavigation(session.id);
                    }}
                  >
                    OPEN SEAT ALLOCATION
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Sessions;
