export const SessionData = [
  {
    "Session Dates": ["14/05/2024", "16/05/2024", "18/05/2024"],
    "Enrollment Deadline": "16/04/2024",
    Location: "Zagreb Office",
    Trainer: "Marko Petrović",
    Cost: "€12.99",
    Duration: "8 h",
    "Seat Allocation": "Open Seat Allocation",
    id: 1,
  },
  {
    "Session Dates": ["14/06/2024", "16/06/2024", "18/06/2024"],
    "Enrollment Deadline": "14/05/2024",
    Location: "Online Microsoft Teams",
    Trainer: "Adam Nowak",
    Cost: "Free",
    Duration: "8 h",
    "Seat Allocation": "Open Seat Allocation",
    id: 2,
  },
  {
    "Session Dates": ["14/07/2024", "16/07/2024", "18/07/2024"],
    "Enrollment Deadline": "14/06/2024",
    Location: "Online Microsoft Teams",
    Trainer: "Ana Negulescu",
    Cost: "Free",
    Duration: "8 h",
    "Seat Allocation": "Open Seat Allocation",
    id: 3,
  },
];
