import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import SpinnerLoader from "../../components/SpinnerLoader";
import { getAuth } from "../../redux/slices/auth.slice";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const { message, loading, isAuthorized } = useSelector(
    (state: RootState) => state.auth
  );

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAuth());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthorized) navigate("/courses");
  }, [isAuthorized]);

  return (
    <div>
      {!loading && <p>{message}</p>}
      {loading && <SpinnerLoader />}
    </div>
  );
}

export default HomePage;
